import React from "react"
import {
  Box,
  useDisclosure,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Text,
  PseudoBox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/core"
import { Link } from "gatsby"
import { useMainPages } from "../../../hooks/useMainPages"
import LogoImage from "../../images/LogoImage"
import { GiHamburgerMenu } from "react-icons/gi"
import { useChildren } from "../../../hooks/useChildren"
import { NewMenuBar } from "../../compare/header/NewMenuBar"

type Props = {
  blogTemplate?: boolean
}

export const MenuBar: React.FC<Props> = ({ blogTemplate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box
      display="flex"
      height="50px"
      m="0rem"
      p={["1rem 0rem", "1rem 0rem"]}
      alignItems="center"
      bg="transparent"
      justifyContent="space-between"
      marginX={["1rem", "3rem", "3rem", "110px"]}
      zIndex={100}
    >
      <Box width={["160px", "160px", "220px", "250px"]}>
        <Link to="/">
          <Box>
            <LogoImage />
          </Box>
        </Link>
      </Box>
      <NewMenuBar />
    </Box>
  )
}
