import React, { useState } from "react"
import { FaArrowCircleUp } from "react-icons/fa"
import { PseudoBox } from "@chakra-ui/core"

export const ScrollUp = () => {
  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }
  typeof window !== "undefined" &&
    window.addEventListener("scroll", checkScrollTop)
  const scrollTop = () => {
    typeof window !== "undefined" &&
      window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <PseudoBox
      position="fixed"
      bottom="20px"
      right="20px"
      alignItems="center"
      justifyContent="center"
      z-index="10000"
      cursor="pointer"
      animation="fadeIn 0.3s"
      transition="all 0.4s"
      opacity={0.5}
      color="#1D3557"
      display={showScroll ? "flex" : "none"}
      _hover={{ color: "#457B9D", opacity: 1 }}
    >
      <FaArrowCircleUp size="50px" onClick={scrollTop} />
    </PseudoBox>
  )
}
