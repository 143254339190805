import { useStaticQuery, graphql } from "gatsby"
import { MetaData } from "../types/MetaData"

export const useMetaData = () => {
  const data = useStaticQuery(graphql`
    query MetaDataQuery {
      site {
        siteMetadata {
          author
          description
          title
          siteUrl
          urlRedirectFix
        }
      }
    }
  `)

  const result: MetaData = data.site.siteMetadata

  return result
}
