import { useStaticQuery, graphql } from "gatsby"
import { Region } from "../types/Region"

export const useRegions = () => {
  const data = useStaticQuery<{
    pages: { edges: { node: { id: string; frontmatter: Region } }[] }
  }>(graphql`
    query RegionPagesQuery {
      pages: allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "region" } } }
        sort: { fields: id, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              bookingId
              type
              parent
              title
              filter
              urlFix
              path
              img
              metaTitle
              metaDesc
            }
          }
        }
      }
    }
  `)

  return data.pages.edges
}
