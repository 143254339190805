import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/core"
// @ts-ignore
import Hamburger from "../../../assets/menu.svg"
import React from "react"
import { Link } from "gatsby"
import { useRegions } from "../../../hooks/useRegions"

export const NewMenuBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const regions = useRegions()

  const links = regions.map((region, i) => (
    <Link to={region.node.frontmatter.path} key={i}>
      <Text
        m="0rem"
        p="0rem"
        color="black"
        fontSize="1rem"
        fontWeight="500"
        lineHeight="1rem"
      >
        {region.node.frontmatter.name}
      </Text>
    </Link>
  ))
  return (
    <>
      <Button ref={btnRef} onClick={onOpen} background="white" border="none">
        <Hamburger width="50px" height="50px" />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        // @ts-ignore
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent overflowY="scroll">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Valitse maakunta</DrawerHeader>
          <DrawerBody>
            <Box>{links}</Box>
            <DrawerHeader borderBottomWidth="1px">Suomen kylpylät</DrawerHeader>
            <Link to="/kylpylat">
              <Text
                m="0rem"
                p="0rem"
                color="black"
                fontSize="1rem"
                fontWeight="500"
                lineHeight="1rem"
              >
                Kaikki Suomen kylpylät
              </Text>
            </Link>
            <Link to="/kylpylat/suomen-paras">
              <Text
                m="0rem"
                p="0rem"
                color="black"
                fontSize="1rem"
                fontWeight="500"
                lineHeight="1rem"
              >
                Suomen paras kylpylä
              </Text>
            </Link>
            <Link to="/kylpylat/romanttinen-viikonloppu-kahdelle">
              <Text
                m="0rem"
                p="0rem"
                color="black"
                fontSize="1rem"
                fontWeight="500"
                lineHeight="1rem"
              >
                Romanttinen viikonloppu kahdelle
              </Text>
            </Link>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
