import React from "react"
import { Box, ThemeProvider, CSSReset } from "@chakra-ui/core"
import customTheme from "../theme/theme"
import { MenuBar } from "../navigation/menuBar/MenuBar"
import { Footer } from "../footer/Footer"
import { Global } from "@emotion/core"
import CookieConsent from "react-cookie-consent"
import { ScrollUp } from "../navigation/scroll/ScrollUp"

type Props = {}

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Global
        styles={theme => ({
          body: {
            fontFamily: "Montserrat",
            margin: "0px",
            lineHeight: "2rem",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            msWordBreak: "break-word",
            wordBreak: "break-word",
            msHyphens: "auto",
            MozHyphens: "auto",
            WebkitHyphens: "auto",
            hyphens: "auto",
          },
          h1: {
            margin: "0px",
            color: "#2B373B",
          },
          h2: {
            margin: "0px",
            marginTop: "6rem",
            fontSize: "2rem",
          },
          h3: {
            fontWeight: 700,
            marginTop: "4rem",
          },
          h4: {
            margin: "0px",
            fontSize: "1.5rem",
          },
          h6: {
            margin: "0px",
            color: "#00AEE0",
            fontWeight: 800,
            marginTop: "4rem",
            fontSize: "1.1rem",
          },
          strong: {
            color: "#00AEE0",
          },
          a: {
            textDecoration: "none",
            outline: "none",
            color: "#FFB400",
            fontSize: "1.2rem",
            fontWeight: 700,
            padding: "0rem 0.3rem",
          },
          input: {
            backgroundColor: "#F4F4F4",
            color: "action",
            border: "1px solid #E5E5E5",
            borderColor: "#E5E5E5",
            outline: "none",
            borderRadius: "10px",
            fontWeight: 800,
            height: "40px",
            margin: "1rem",
            fontSize: "0.9rem",
            paddingLeft: "2rem",
            width: "180px",
          },
        })}
      />
      <Box>
        <MenuBar />
        <Box m="0rem" minH="86vh">
          {children}
        </Box>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="cookiesdassd1234566"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Tällä sivustolla käytetään evästeitä. Jatkamalla sivuston käyttöä
          hyväksyt evästeiden käytön.
        </CookieConsent>
        <ScrollUp />
      </Box>
    </ThemeProvider>
  )
}
